import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Geolocation } from '@capacitor/geolocation';
import { AlertController, LoadingController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import firebase from 'firebase/compat/app';
import { AddressDetailPickerComponent } from 'src/app/components/address-detail-picker/address-detail-picker.component';
import { AddressService } from 'src/app/services/address/address.service';
import { Address } from 'src/app/types/address';
import { AddressAutocompleteComponent } from '../address-autocomplete/address-autocomplete.component';
import { ProfileAddressComponent } from '../profile-address/profile-address.component';
import { BehaviorSubject } from 'rxjs';
import { GoogleMap } from '@angular/google-maps';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-add-new-address',
    templateUrl: './add-new-address.component.html',
    styleUrls: ['./add-new-address.component.scss'],
})
export class AddNewAddressComponent implements OnInit, AfterViewInit {
    counter = 0;
    mapLoading = true;
    @ViewChild('map', { static: false }) map: GoogleMap;
    zoom = 15;
    isWeb = false;
    mapRef: ElementRef<HTMLElement>;
    options = {
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scrollwheel: true,
    } as google.maps.MapOptions;

    mapCenter: google.maps.LatLngLiteral = {
        lat: 48.137149911784654,
        lng: 11.575146872048869
    }
    zoomChangedEvent = new BehaviorSubject(15);


    addressInput: string;
    address: Address;
    shouldShow = false;
    constructor(private _ref: ChangeDetectorRef,
        private _auth: AngularFireAuth,
        private _firestore: AngularFirestore,
        private _loading: LoadingController,
        private _location: Location,
        private _popup: PopoverController,
        private _address: AddressService,
        private _translate: TranslateService,
        private _platform: Platform,
        private http: HttpClient,
        private _alert: AlertController,
        private _modal: ModalController) {
        if (this._platform.is('desktop') || this._platform.is('mobileweb')) {
            this.isWeb = true;
        }
    }

    ngOnInit(): void { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.loadLocation()
        }, 200)
    }

    async dismiss() {
        this._modal.dismiss();
    }
    async loadLocation() {
        let lat = 51.220915;
        let lng = 9.357579;

        const permisison = await Geolocation.checkPermissions();
        if (permisison.location == 'granted') {
            const gps = await Geolocation.getCurrentPosition();
            lat = gps.coords.latitude;
            lng = gps.coords.longitude;

        }

        this.mapCenter = {
            lat,
            lng
        };
        const latlng = new google.maps.LatLng(lat, lng);
        this.map.googleMap.setCenter(latlng);
        this.zoomChanged();
    }


    findLocation() {
        Geolocation.getCurrentPosition().then(result => {
            const latlng = new google.maps.LatLng(result.coords.latitude, result.coords.longitude);
            this.map.googleMap.setCenter(latlng);


            this.zoomChanged();
        }, error => {
            this.showAlert();
        });
    }

    async zoomChanged() {
        this.zoom = this.map.getZoom();
        this.mapCenter = {
            lat: this.map.getCenter().lat(),
            lng: this.map.getCenter().lng()
        };
        this.zoomChangedEvent.next(this.zoom);
        const result = await this._address.reverseGeoCode({
            lat: this.map.getCenter().lat(),
            lng: this.map.getCenter().lng()
        });
        this.address = result;
        this.convertAddressToText(result);
    }

    convertAddressToText(input) {
        this.addressInput = `${input.street} ${input.housenumber} ${input.town} ${input.postCode}`;


        this._ref.detectChanges();
    }



    async showAlert() {
        const headline = await this._translate.get('no_gps_headline').toPromise();
        const button = await this._translate.get('ok').toPromise();
        const message = await this._translate.get('no_gps_body').toPromise();
        const alert = await this._alert.create({
            header: headline,
            message: message,
            buttons: [
                {
                    text: button,
                    handler: () => {

                    }
                }
            ]
        });
        await alert.present();
    }


    async addAddress() {
        await this.showAddressDetail();
        const user = await this._auth.currentUser;
        if (!this.address) {
            const alert = await this._alert.create({
                header: "Fehler",
                message: "Leider konnte die Adresse nicht ermittelt werden. Bitte versuche es erneut",
                buttons: [
                    {
                        text: 'OK',
                        handler: () => {

                        }
                    }
                ]
            });
            await alert.present();
        } else {
            await this._loading.create();
            const user = await this._auth.currentUser;
            const idToken = await user.getIdToken();
            this.http.post<any>(`${environment.api_url}/address`, this.address, {
                headers: {
                    authorization: 'Bearer ' + idToken
                }
            }).subscribe(result => {
                this._modal.dismiss();
                this._loading.dismiss();
            })
        }
    }

    async showAddressDetail() {
        if (this.address && this.address.country && this.address.housenumber && this.address.postCode &&
            this.address.street && this.address.town) {
            return true;
        }
        const modal = await this._popup.create({
            component: AddressDetailPickerComponent,
            componentProps: {
                address: this.address
            },
            cssClass: 'address-detail',
            backdropDismiss: false,
            id: 'address-detail'
        });
        modal.present();
        const result = await modal.onWillDismiss();
        this.address = result.data;


        return true;
    }

    async openAutocomplete() {
        const modal = await this._modal.create({
            component: AddressAutocompleteComponent
        });
        modal.present();
        const result = await modal.onDidDismiss();
        if (result.data) {
            this.convertAddressToText(result.data.address);
            this.address = result.data.address;
            this.addAddress();
        }
    }
}