import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PricePipe } from './pipes/price/price.pipe';
import { UnitPipe } from './pipes/unit/unit.pipe';
import { HtmlDirective } from './pipes/safehtml/safehtml.directive';
import { ProductComponent } from './components/product/product.component';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { NgxGdprCookieConsentModule } from "ngx-gdpr-cookie-consent";

import { SwiperModule } from 'swiper/angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
  declarations: [
    PricePipe,
    UnitPipe,
    HtmlDirective,
    ProductComponent,
    LoaderComponent
  ],
  imports: [
    TranslateModule,
    IonicModule,
    NgxGdprCookieConsentModule,
    SwiperModule,
    LazyLoadImageModule,
  ],
  exports: [
    PricePipe,
    NgxGdprCookieConsentModule,
    UnitPipe,
    TranslateModule,
    HtmlDirective,
    ProductComponent,
    CommonModule,
    LoaderComponent,
    SwiperModule,
    LazyLoadImageModule
  ]
})
export class SharedModule { }
