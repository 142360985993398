import { Injectable } from '@angular/core';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { BehaviorSubject, from } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { CartProduct, Product } from 'src/app/types/product';
import * as dayjs from 'dayjs';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface DeliveryTime {
  date: string;
  time: string;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {

  cart = new BehaviorSubject<CartProduct[]>([]);
  checkoutCart = new BehaviorSubject<CartProduct[]>([]);
  deliveryDate: DeliveryTime;
  currentOrder: string;
  affiliateCode = '';
  constructor(private _plattform: Platform,
    private _alert: AlertController,
    private router: Router,
    private _loading: LoadingController,
    private afAuth: AngularFireAuth,
    private _http: HttpClient,
    private _translate: TranslateService) {
    this.loadCart();
    this.getOrderId();
  }

  async loadDeliveryDate(): Promise<DeliveryTime> {
    const date = await Preferences.get({ key: 'deliveryDate' });
    if (date?.value) {
      try {
        this.deliveryDate = JSON.parse(date.value);
        return this.deliveryDate;
      } catch (e) {
        return null;
      }
    }
    return null;
  }



  async setDeliveryDate(date: DeliveryTime) {
    if (date == null) {
      await Preferences.remove({ key: 'deliveryDate' });
      this.deliveryDate = null;
      return;
    }
    await Preferences.set({ key: 'deliveryDate', value: JSON.stringify(date) });
    this.performHaptics();
    this.deliveryDate = date;
  }

  async setOrderId(orderId: string) {
    await Preferences.set({ key: 'currentOrderId', value: orderId });
    this.currentOrder = orderId;
  }

  async getOrderId() {
    const orderId = await Preferences.get({ key: 'currentOrderId' });
    if (orderId?.value) {
      this.currentOrder = orderId.value;
    }
  }

  async removeOrderId() {
    await Preferences.remove({ key: 'currentOrderId' });
    this.currentOrder = null;
  }

  public deleteCart() {
    Preferences.remove({ key: 'cart' })
    this.cart.next([]);
    this.checkoutCart.next([]);
    this.removeOrderId();
  }

  public getCart() {
    return this.cart.asObservable();
  }

  public getCheckoutCart() {
    return this.checkoutCart.asObservable();
  }

  public async removeProducts(products: string[]) {
    let cartData = await Preferences.get({ key: 'cart' });
    if (cartData.value) {
      let cart = JSON.parse(cartData.value) as CartProduct[];
      cart = cart.filter(item => products.includes(item.product._id) == false);
      Preferences.set({ key: 'cart', value: JSON.stringify(cart) });
      this.cart.next(cart);
      this.checkoutCart.next(cart);
    }
  }

  public async getProductQTY(product: Product) {
    let cartData = await Preferences.get({ key: 'cart' });
    if (cartData.value) {
      let cart = JSON.parse(cartData.value) as CartProduct[];
      const found = cart.find(p => p.product._id == product._id);
      if (found) {
        return found.qty;
      }
    }
    return 0;
  }

  public async addToCart(product: Product, qty: number = 1, fromCartView = false) {
    return new Promise(async (resolve, reject) => {
      let cartData = await Preferences.get({ key: 'cart' });
      this.removeOrderId();
      if (cartData.value) {
        let cart = JSON.parse(cartData.value) as CartProduct[];

        const index = cart.findIndex(p => p.product._id === product._id);
        if (index !== -1) {
          cart[index].qty = cart[index].qty + 1;
        } else {
          cart.push({
            product,
            qty
          });
        }
        Preferences.set({ key: 'cart', value: JSON.stringify(cart) });
        if (!fromCartView) {
          this.cart.next(cart);
        }
        this.checkoutCart.next(cart)
        resolve(true);
      } else {
        const cart = [
          {
            product,
            qty
          }
        ]
        Preferences.set({ key: 'cart', value: JSON.stringify(cart) });
        if (!fromCartView) {
          this.cart.next(cart);
        }
        this.checkoutCart.next(cart)
        resolve(true);
      }
    })


  }

  public async loadCart() {
    let cartData = await Preferences.get({ key: 'cart' });
    if (cartData.value) {
      let cart = JSON.parse(cartData.value) as CartProduct[];
      this.cart.next(cart);
      this.checkoutCart.next(cart);
    } else {
      Preferences.set({ key: 'cart', value: JSON.stringify([]) });
      this.cart.next([]);
      this.checkoutCart.next([]);
    }
  }

  public async removeFromCart(product: Product, entireItem = false, fromCartView = false) {
    this.removeOrderId();
    let cartData = await Preferences.get({ key: 'cart' });
    if (cartData.value) {
      let cart = JSON.parse(cartData.value) as CartProduct[];
      const index = cart.findIndex(p => p.product._id === product._id);
      if (index !== -1) {
        if (cart[index].qty > 1 && !entireItem) {
          cart[index].qty = cart[index].qty - 1;
        } else {
          cart.splice(index, 1);
        }
      }
      Preferences.set({ key: 'cart', value: JSON.stringify(cart) });
      if (!fromCartView) {
        this.cart.next(cart);
      }
      this.checkoutCart.next(cart)
    }
  }

  async performHaptics() {
    if (!this._plattform.is('mobileweb') && !this._plattform.is('desktop')) {
      await Haptics.impact({ style: ImpactStyle.Heavy });
    }
  }

  async saveAffiliate(code: string) {

    const loading = await this._loading.create({
    });
    loading.present();


    const sub = this.afAuth.authState.subscribe(async result => {
      sub.unsubscribe();
      if (result) {
        this.validateCode(code, loading);
      } else {
        this.affiliateCode = code;
        Preferences.set({ key: 'affiliate', value: code }).then(async result => {
          const headline = await this._translate.get('affiliate_user_required').toPromise();
          const button = await this._translate.get('ok').toPromise();
          const cancel = await this._translate.get('cancel').toPromise();
          const message = await this._translate.get('affiliate_user_required_body').toPromise();
          loading.dismiss();
          const alert = await this._alert.create({
            header: headline,
            message: message,
            buttons: [
              {
                text: button,
                handler: () => {
                  this.router.navigate(['/login'])
                }
              },
              {
                text: cancel,
                handler: () => {
                }
              },
            ]
          });
          await alert.present();
        });
      }
    })
  }

  validateCode(code: string, loading) {
    this.afAuth.authState.subscribe(user => {
      user.getIdToken().then(token => {
        this._http.get<any>(`${environment.api_url}/affiliate/validate/${code}`, {
          headers: {
            authorization: 'Bearer ' + token
          }
        }).subscribe(async result => {
          loading.dismiss();
          if (result.message == 'ok') {
            this.showAffiliateResponse(true);
          } else {
            this.showAffiliateResponse(false);
          }
        })
      });
    })
  }

  async showAffiliateResponse(success: boolean) {
    let headline = await this._translate.get('affiliate_success_headline').toPromise();
    const button = await this._translate.get('ok').toPromise();
    let message = await this._translate.get('affiliate_success_body').toPromise();
    if (!success) {
      headline = await this._translate.get('affiliate_failed_headline').toPromise();
      message = await this._translate.get('affiliate_failed_body').toPromise();
    }
    const alert = await this._alert.create({
      header: headline,
      message: message,
      buttons: [
        {
          text: button,
          handler: () => {

          }
        },
      ]
    });
    await alert.present();

  }
}
