import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import StockUtil from '../../util/stock';
import { CartService } from 'src/app/services/cart/cart.service';
import { Product } from 'src/app/types/product';
import { Platform } from '@ionic/angular';
import { DiscountService } from 'src/app/services/discount/discount.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Input()
  product: Product;

  @Output()
  clicked: EventEmitter<Product> = new EventEmitter();
  amount: number;

  stock = StockUtil;


  isAuthenticated = true;
  constructor(public _cart: CartService, private _afAuth: AngularFireAuth,
    public _discount: DiscountService,
    private _platform: Platform) { }

  ngOnInit() {
    this.determineIsLoggedIn();
    this.getAmount();
  }
  getAmount() {
    this._cart.checkoutCart.subscribe(result => {
      if (result.filter(prod => prod.product._id == this.product._id)) {
        if (result.filter(prod => prod.product._id == this.product._id)[0]?.qty) {
          this.amount = result.filter(prod => prod.product._id == this.product._id)[0].qty
        } else {
          this.amount = 0;
        }
      }
    });
  }

  async openModal(product: Product) {
    this.clicked.emit(product);
  }


  determineIsLoggedIn() {
    this._afAuth.authState.subscribe(state => {
      this.isAuthenticated = state != null;
    });
  }

  removeFromCart(product: Product) {
    if (this._platform.is('capacitor')) {
      Haptics.impact({
        style: ImpactStyle.Medium
      });
    }
    this._cart.removeFromCart(product, false, false);
  }

  addToCart(product: Product) {
    if (!StockUtil.check(product, this.amount) || !this._discount.checkMaximum(this.amount, product)) {
      return;
    }
    if (this._platform.is('capacitor')) {

      Haptics.impact({
        style: ImpactStyle.Medium
      });
    }
    this._cart.addToCart(product, 1);
  }
  isNew() {
    if (!this.product.createdAt) {
      return true;
    } else {
      const twoWeeksAgo = dayjs().subtract(14, 'days');
      if (twoWeeksAgo.toDate().getTime() < new Date(this.product.createdAt).getTime()) {
        return false
      } else {
        return true;
      }
    }
  }
}
