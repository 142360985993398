import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AddNewAddressComponent } from './components/add-new-address/add-new-address.component';
import { SearchProductsPage } from './pages/search-products/search-products.page';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },

  {
    path: 'affiliate/:code', 
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)    
  },

  {
    path: 'productdetails/:productId',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },

  {
    path: 'tabs/products/search',
    component: SearchProductsPage
  },

  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule)
  },

  {
    path: 'new-address',
    component: AddNewAddressComponent
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
