import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AlertController, LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent implements OnInit {
  loading = true;
  normalPayment = true;
  successText = '';
  checkoutBtnText = '';


  constructor(private _modal: ModalController,
    private _auth: AngularFireAuth,
    private _loading: LoadingController,
    private _http: HttpClient,
    private _alert: AlertController,
    private _translate: TranslateService,
    ) { }

  ngOnInit() {
    this.checkIfAutomaticPaymentEnabled();
  }


  async checkIfAutomaticPaymentEnabled() {
    const loading = await this._loading.create({
    });
    this.checkoutBtnText = await this._translate.get('fast_checkout_btn').toPromise();
    this.successText = await this._translate.get('fast_checkout_btn_success').toPromise();
    loading.present();
    const user = await this._auth.currentUser;
    const idToken = await user.getIdToken();
    this._http.get<any>(`${environment.api_url}/customer`, {
      headers: {
        authorization: 'Bearer ' + idToken
      }
    }).subscribe(result => {
      loading.dismiss();
      this.loading = false;

      if (result.message !== 'payment_method_exists') {
        this.normalPayment = true;
      } else {
        this.normalPayment = false;
      }
    }, error => {
      this.loading = false;
      setTimeout(() => {
        loading.dismiss();
      }, 100)
    });
  }

  dismiss() {
    this._modal.dismiss(null, null, 'payment_methods');
  }



  async showCouponAlert(headlineId: string, bodyId: string) {
    const headline = await this._translate.get(headlineId).toPromise();
    const confirm = await this._translate.get('ok').toPromise();
    const body = await this._translate.get(bodyId).toPromise();
    const alert = await this._alert.create({

      header: headline,
      message: body,
      buttons: [
        {
          text: confirm,
          handler: (alertData) => { //takes the data 
          }
        }
      ],
    });

    await alert.present();
  }


  selectPaymentMethod(kind: string) {
    this._modal.dismiss({ kind: kind });
  }
}
