import { Injectable } from '@angular/core';

import { AlertController, NavController, Platform, ToastController } from '@ionic/angular';

import firebase from 'firebase/compat/app';

import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import {
  SignInWithApple,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<any>;

  private loggedIn = new BehaviorSubject<Boolean>(false);
  options: SignInWithAppleOptions = {
    clientId: 'com.vomtuerken.app.v2',
    redirectURI: 'https://vomtuerken-app.firebaseapp.com/__/auth/handler',
    scopes: 'email name',
  };

  email = '';

  translations = {
    "auth/claims-too-large": "Die Ansprüche Nutzlast vorgesehen setCustomUserClaims() größer als die maximal zulässige Größe von 1000 Bytes.",
    "auth/email-already-in-use": "Die angegebene E-Mail wird bereits von einem bestehenden Benutzer verwendet.",
    "auth/id-token-expired": "Das bereitgestellte Firebase-ID-Token ist abgelaufen.",
    "auth/id-token-revoked": "Das Firebase-ID-Token wurde widerrufen.",
    "auth/insufficient-permission": "Die zum Initialisieren des Admin-SDK verwendeten Anmeldeinformationen haben keine ausreichende Berechtigung zum Zugriff auf die angeforderte Authentifizierungsressource. Siehe ein Projekt Firebase einrichten für Dokumentation, wie eine Berechtigung mit dem entsprechenden Berechtigungen zu erzeugen , und es verwendet , um den Admin - SDKs zu authentifizieren.",
    "auth/internal-error": "Der Authentifizierungsserver hat beim Versuch, die Anforderung zu verarbeiten, einen unerwarteten Fehler festgestellt. Die Fehlermeldung sollte die Antwort des Authentifizierungsservers mit zusätzlichen Informationen enthalten. Wenn der Fehler weiterhin besteht, melden Sie bitte das Problem zu unserem Bug Report Support - Kanal.",
    "auth/invalid-argument": "Einer Authentifizierungsmethode wurde ein ungültiges Argument bereitgestellt. Die Fehlermeldung sollte zusätzliche Informationen enthalten.",
    "auth/invalid-claims": "Die benutzerdefinierte Anspruch Attribute bereitgestellt setCustomUserClaims() sind ungültig.",
    "auth/invalid-continue-uri": "Die Fortsetzungs-URL muss eine gültige URL-Zeichenfolge sein.",
    "auth/invalid-creation-time": "Die Erstellungszeit muss eine gültige UTC-Datumszeichenfolge sein.",
    "auth/invalid-credential": "Die zur Authentifizierung der Admin-SDKs verwendeten Anmeldeinformationen können nicht zum Ausführen der gewünschten Aktion verwendet werden. Bestimmte Authentifizierungsverfahren wie createCustomToken() und verifyIdToken() erfordern das SDK mit einem Zertifikat Nachweis für ein Aktualisierungs - Token oder Application Default Credential im Gegensatz initialisiert werden. Siehe Initialisieren des SDK für Dokumentation, wie die Admin SDKs mit einem Zertifikat Anmeldeinformationen zu authentifizieren.",
    "auth/invalid-disabled-field": "Der bereitgestellte Wert für die disabled Benutzereigenschaft ist ungültig. Es muss ein boolescher Wert sein.",
    "auth/invalid-display-name": "Der bereitgestellte Wert für die displayName ist ungültig. Es muss eine nicht leere Zeichenfolge sein.",
    "auth/invalid-dynamic-link-domain": "Die bereitgestellte dynamische Linkdomäne ist für das aktuelle Projekt nicht konfiguriert oder autorisiert.",
    "auth/invalid-email": "Die eingegebene E-Mail Adresse ist ungültig!",
    "auth/invalid-email-verified": "Der bereitgestellte Wert für die emailVerified Benutzereigenschaft ist ungültig. Es muss ein boolescher Wert sein.",
    "auth/invalid-hash-algorithm": "Der Hash-Algorithmus muss mit einer der Zeichenfolgen in der Liste der unterstützten Algorithmen übereinstimmen.",
    "auth/invalid-hash-block-size": "Die Hash-Blockgröße muss eine gültige Zahl sein.",
    "auth/invalid-hash-derived-key-length": "Die vom Hash abgeleitete Schlüssellänge muss eine gültige Zahl sein.",
    "auth/invalid-hash-key": "Der Hash-Schlüssel muss ein gültiger Byte-Puffer sein.",
    "auth/invalid-hash-memory-cost": "Die Hash-Speicherkosten müssen eine gültige Zahl sein.",
    "auth/invalid-hash-parallelization": "Die Hash-Parallelisierung muss eine gültige Zahl sein.",
    "auth/invalid-hash-rounds": "Die Hashrunden müssen eine gültige Zahl sein.",
    "auth/invalid-hash-salt-separator": "Das Salt-Separator-Feld des Hash-Algorithmus muss ein gültiger Byte-Puffer sein.",
    "auth/invalid-id-token": "Das bereitgestellte ID-Token ist kein gültiges Firebase-ID-Token.",
    "auth/invalid-last-sign-in-time": "Der Zeitpunkt der letzten Anmeldung muss eine gültige UTC-Datumszeichenfolge sein.",
    "auth/invalid-page-token": "Die bereitgestellten nächste Seite Token in listUsers() ist ungültig. Es muss sich um eine gültige, nicht leere Zeichenfolge handeln.",
    "auth/invalid-password": "Der angegebene Wert für die password Benutzereigenschaft ist ungültig. Es muss sich um eine Zeichenfolge mit mindestens sechs Zeichen handeln.",
    "auth/invalid-password-hash": "Der Passwort-Hash muss ein gültiger Byte-Puffer sein.",
    "auth/invalid-password-salt": "Der Passwort-Salt muss ein gültiger Byte-Puffer sein",
    "auth/invalid-phone-number": "Der bereitgestellte Wert für den phoneNumber ist ungültig. Es muss sich um eine nicht leere mit dem E.164-Standard kompatible Kennungszeichenfolge handeln.",
    "auth/invalid-photo-url": "Der bereitgestellte Wert für die photoURL Benutzereigenschaft ist ungültig. Es muss sich um eine Zeichenfolgen-URL handeln.",
    "auth/invalid-provider-data": "Die providerData muss ein gültiges Array von UserInfo-Objekten sein.",
    "auth/invalid-provider-id": "Die providerId muss eine gültige unterstützte Anbieterkennungszeichenfolge sein.",
    "auth/invalid-oauth-responsetype": "Nur genau ein OAuth responseType sollte auf true gesetzt werden.",
    "auth/invalid-session-cookie-duration": "Die Dauer des Sitzungscookies muss eine gültige Zahl in Millisekunden zwischen 5 Minuten und 2 Wochen sein.",
    "auth/invalid-uid": "Die bereitgestellte uid muss eine nicht leere Zeichenkette mit maximal 128 Zeichen.",
    "auth/invalid-user-import": "Der zu importierende Benutzerdatensatz ist ungültig.",
    "auth/maximum-user-count-exceeded": "Die maximal zulässige Anzahl von Benutzern zum Importieren wurde überschritten.",
    "auth/missing-android-pkg-name": "Ein Android-Paketname muss angegeben werden, wenn die Android-App installiert werden muss.",
    "auth/missing-continue-uri": "In der Anfrage muss eine gültige Weiter-URL angegeben werden.",
    "auth/missing-hash-algorithm": "Das Importieren von Benutzern mit Kennwort-Hashes erfordert, dass der Hashing-Algorithmus und seine Parameter bereitgestellt werden.",
    "auth/missing-ios-bundle-id": "In der Anfrage fehlt eine iOS-Bundle-ID.",
    "auth/missing-uid": "Eine uid Identifikator wird für den aktuellen Betrieb erforderlich.",
    "auth/missing-oauth-client-secret": "Der geheime Schlüssel der OAuth-Konfiguration ist erforderlich, um den OIDC-Codefluss zu aktivieren.",
    "auth/operation-not-allowed": "Der angegebene Anmeldeanbieter ist für Ihr Firebase-Projekt deaktiviert. Aktivieren Sie es aus dem Sign-in - Methode Abschnitt der Konsole Firebase.",
    "auth/phone-number-already-exists": "Der mitgelieferte phoneNumber ist bereits von einem vorhandenen Benutzer. Jeder Benutzer muss eine eindeutige haben phoneNumber .",
    "auth/project-not-found": "Für die zum Initialisieren der Admin-SDKs verwendeten Anmeldeinformationen wurde kein Firebase-Projekt gefunden. Siehe ein Projekt Firebase einrichten für Dokumentation, wie eine Berechtigung für Ihr Projekt zu generieren und es verwendet , um den Admin - SDKs zu authentifizieren.",
    "auth/reserved-claims": "Eine oder mehrere benutzerdefinierte Ansprüche bereitgestellt setCustomUserClaims() sind reserviert. Zum Beispiel OIDC spezifische Ansprüche wie (sub, iat, iss, exp, AUD, auth_time, usw.) sollen nicht als Schlüssel für die individuellen Ansprüche verwendet werden.",
    "auth/session-cookie-expired": "Das bereitgestellte Firebase-Sitzungscookie ist abgelaufen.",
    "auth/session-cookie-revoked": "Das Firebase-Sitzungscookie wurde widerrufen.",
    "auth/uid-already-exists": "Die bereitgestellte uid ist bereits von einem vorhandenen Benutzer. Jeder Benutzer muss eine eindeutige haben uid .",
    "auth/unauthorized-continue-uri": "Die Domain der Weiter-URL steht nicht auf der Whitelist. Setzen Sie die Domain in der Firebase Console auf die Whitelist.",
    "auth/user-not-found": "Es gibt keinen vorhandenen Benutzerdatensatz, der der bereitgestellten Kennung entspricht.",
    "else": "Serverfehler.",
    "auth/wrong-password": "Die eingegebenen Zugangsdaten sind ungültig!",
    "auth/weak-password": "Das Passwort muss mindestens aus 6 Zeichen bestehen."
  };

  constructor(private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private plattform: Platform,
    private toastController: ToastController,
    private router: Router,
    private _nav: NavController,
    private _translate: TranslateService,
    private _alertController: AlertController,
    private _router: Router) {
    this.afAuth.authState.subscribe(result => {
      if (result && result.emailVerified) {
        this.loggedIn.next(true);
      } else {
        this.loggedIn.next(false);
      }
    });
  }



  emailForgot(email) {
    return this.afAuth.sendPasswordResetEmail(email).then(async res => {
      const message = await this._translate.get('verify_mail_sent').toPromise()
      this.showToast(message);
    }).catch(err => {
      console.error(err);
      this.showToast(this.translations[err.code]);
    });
  }

  emailSignUp(pw, userdata) {
    return this.afAuth.createUserWithEmailAndPassword(userdata.email, pw).then(res => {
      this.afAuth.user.subscribe(x => {
        if (x) {
          x.sendEmailVerification()
            .then(() => {
              const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${x.uid}`);
              userRef.set(userdata, { merge: true }).catch(error => {
                console.error(error);
              }).then(result => {
                this._router.navigate(['/tabs/products'])
              });
            })
            .catch(err => {
              throw (err);
            });
        }
      });
    })
      .catch(err => {
        console.error(err);
        this.showToast(this.translations[err.code]);
        throw (err);
      });
  }

  getLoggedIn(): Observable<Boolean> {
    return this.loggedIn.asObservable();
  }

  async showToast(input) {
    const toast = await this.toastController.create({
      message: input,
      color: 'dark',
      duration: 2000
    });
    await toast.present();
  }

  resetPW() {
    this.afAuth.sendPasswordResetEmail(this.email).then(async result => {
      this.performHaptics();
      const message = await this._translate.get('verify_mail_sent').toPromise();
      this.showToast(message);
      this.router.navigate(['/login']);
    })
  }

  async checkProfile() {
    const user = await this.afAuth.currentUser;
    const profile = await this.afs.collection('users').doc(user.uid).get().toPromise();
    if (!profile || !profile.data() || !profile.get('firstname') || !profile.get('mobile')) {
      this.router.navigate(['/login/details'])
      return false;
    }
    return true;
  }

  async showToastEmailVerify(user: firebase.User) {
    const message = await this._translate.get('mail_not_verified').toPromise();
    const buttonContent = await this._translate.get('mail_not_verified_button').toPromise();
    const successMessage = await this._translate.get('mail_not_verified_success').toPromise();
    const failedMessage = await this._translate.get('mail_not_verified_failed').toPromise();
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      color: 'dark',
      buttons: [
        {
          text: buttonContent,
          handler: () => {
            this.toastController.dismiss();
            setTimeout(() => {
              user.sendEmailVerification().then(() => {
                this.showToast(successMessage);
              }).catch(error => {
                this.showToast(failedMessage);
              });
            }, 400);
          }
        }
      ]
    });
    await toast.present();
  }

  emailSignin(pw, email) {
    return this.afAuth.signInWithEmailAndPassword(email, pw).then(res => {
      if (!res.user.emailVerified) {
        return this.showToastEmailVerify(res.user);
      } else {
        this.loggedIn.next(true);
        this._nav.navigateRoot('/tabs/products', { animated: true, animationDirection: 'forward' });

      }
    })
      .catch(err => {
        this.showToast(this.translations[err.code]);
        throw (err);
      });
  }

  async logout() {


    return new Promise(async (resolve, reject) => {

      const message = await this._translate.get('logout_message').toPromise();
      const headline = await this._translate.get('logout_headline').toPromise();
      const yesButton = await this._translate.get('yes').toPromise();
      const noButton = await this._translate.get('no').toPromise();


      const alert = await this._alertController.create({
        header: headline,
        message: message,
        buttons: [{
          text: yesButton,
          handler: (blah) => {
            this.afAuth.signOut();
            this.loggedIn.next(false);
            resolve(true);
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 300);
          }
        }, {
          text: noButton
        }]
      });

      await alert.present();
    });
  }

  public performAppleLogin() {
    if (this.plattform.is('mobile')) {
      if (this.plattform.is('mobileweb')) {
        this.performAppleWebLogin();
      } else {
        this.performNativeAppleLogin();
      }
    } else {
      this.performAppleWebLogin();
    }
  }


  private async performNativeAppleLogin() {
    const failedMessage = await this._translate.get('login_failed').toPromise();

    SignInWithApple.authorize(this.options).then(async res => {

      if (res.response && res.response.identityToken) {
        await this.createFirebaseuser(res.response);
        this.performHaptics();
      } else {
        this.showToast(failedMessage);
      }
    }).catch(response => {
      this.showToast(failedMessage);
    });
  }

  private async performAppleWebLogin() {
    const failedMessage = await this._translate.get('login_failed').toPromise();
    const provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope("name");
    provider.addScope("email");
    const result = await this.afAuth.signInWithPopup(provider);
    if (result) {
      this.performHaptics();
      this.createUserDoc(result.user.uid);
    } else {
      this.showToast(failedMessage);
    }
  }

  async performHaptics() {
    if (!this.plattform.is('mobileweb') && !this.plattform.is('desktop')) {
      await Haptics.impact({ style: ImpactStyle.Heavy });
    }
  }

  async createUserDoc(userId) {
    const user = await this.afAuth.currentUser;
    this.afs.collection('users').doc(userId).get().subscribe(result => {
      if (result && result.get('firstname') && result.get('firstname') !== '') {

        this._nav.navigateRoot('/tabs/products', { animated: true, animationDirection: 'forward' });

      } else {
        if (user.providerData) {
          let data = {};
          if (user.providerData.length > 0 && user.providerData[0].displayName) {
            data = {
              email: user.email,
              firstname: user.providerData[0].displayName.split(/(\s+)/)[0],
              lastname: user.providerData[0].displayName.split(/(\s+)/)[2],
            }
            const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${userId}`);
            userRef.set(data, { merge: true });
            this._nav.navigateRoot('/tabs/products', { animated: true, animationDirection: 'forward' });
          } else {
            this._nav.navigateRoot('/tabs/products', { animated: true, animationDirection: 'forward' });
          }
        } else {
          this._nav.navigateRoot('/tabs/products', { animated: true, animationDirection: 'forward' });
        }
      }
    })
  }

  private async createFirebaseuser(appleResponse) {
    // Create a custom OAuth provider
    const provider = new firebase.auth.OAuthProvider('apple.com');
    // Create sign in credentials with our token
    const credential = provider.credential({
      idToken: appleResponse.identityToken,
      accessToken: appleResponse.rawNonce
    });
    // Call the sign in with our created credentials
    const userCredential = await this.afAuth.signInWithCredential(credential);

    this.createUserDoc(userCredential.user.uid);
    // Update the user document in Firestore
  }


  public async performGoogleLogin() {
    const failedMessage = await this._translate.get('login_failed').toPromise();
    if (this.plattform.is('mobile')) {
      if (this.plattform.is('mobileweb')) {
        this.authLogin(new firebase.auth.GoogleAuthProvider());
      } else {
        const googleUser = await GoogleAuth.signIn();
        const credential = firebase.auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);
        this.afAuth.signInWithCredential(credential).then(async user => {
          this.createUserDoc(user.user.uid);
          this.performHaptics();
        }).catch(err => {
          if (this.translations[err.code]) {
            this.showToast(this.translations[err.code]);
          } else {
            this.showToast(failedMessage);
          }
        });
      }
    } else {
      this.authLogin(new firebase.auth.GoogleAuthProvider());
    }
  }

  private authLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then(async (result) => {
        this.performHaptics();

        this.createUserDoc(result.user.uid);
      }).catch(async (error) => {
        this.performHaptics();
      });
  }

}