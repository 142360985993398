import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';
import { Address } from 'src/app/types/address';

@Component({
  selector: 'app-address-detail-picker',
  templateUrl: './address-detail-picker.component.html',
  styleUrls: ['./address-detail-picker.component.scss'],
})
export class AddressDetailPickerComponent implements OnInit {

  group = this._builder.group({
    address: ['', [Validators.required, Validators.minLength(2)]],
    appartment: ['', [Validators.required, Validators.minLength(1)]],
    postalCode: ['', [Validators.required, Validators.pattern(/[0-9]/), Validators.minLength(4)]]
  });

  @Input()
  address: Address;

  constructor(private _builder: FormBuilder,
    private _popover: PopoverController) { }

  ngOnInit() {
    this.group.setValue({
      address: this.address.street,
      appartment: this.address.housenumber,
      postalCode: this.address.postCode
    });
    this.group.markAllAsTouched();
  }

  cancel() {
    this._popover.dismiss(null,null,'address-detail');
  }

  confirm() {
    if (this.group.invalid) {
      return;
    }

    this.address.postCode = this.group.get('postalCode').value;
    this.address.street = this.group.get('address').value;
    this.address.housenumber = this.group.get('appartment').value;

    this._popover.dismiss(this.address, null, 'address-detail');
  }

}
