import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Preferences } from '@capacitor/preferences';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-profile-language',
  templateUrl: './profile-language.component.html',
  styleUrls: ['./profile-language.component.scss'],
})
export class ProfileLanguageComponent implements OnInit {

  languages = [
    {
      code: 'de',
      text: 'Deutsch'
    },
    {
      code: 'en',
      text: 'Englisch'
    },
    {
      code: 'tr',
      text: 'Türkisch'
    }
  ];
  currentLanguage = 'de';

  constructor(private _lang: TranslateService, private _afAuth: AngularFireAuth, private _afs: AngularFirestore) { }

  ngOnInit() {
    this.loadLanguage();
  }

  selectLanguage() {
    Preferences.set({ key: 'language', value: this.currentLanguage });
    this._lang.use(this.currentLanguage);
    this._lang.reloadLang(this.currentLanguage);
    require(`../../../../assets/i18n/dayjs/${this.currentLanguage}.js`);
    dayjs.locale(this.currentLanguage);
    this.storeInDB();
  }
  storeInDB() {
    this._afAuth.authState.subscribe(user => {
      if (user) {
        this._afs.collection('users').doc(user.uid).update({
          language: this.currentLanguage
        })
      }
    })
  }

  async loadLanguage() {
    this.currentLanguage = (await Preferences.get({ key: 'language' })).value ?? 'de';
  }

}
