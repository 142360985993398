import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Product } from 'src/app/types/product';

@Pipe({
  name: 'unit'
})
export class UnitPipe implements PipeTransform {

  constructor(private _translate: TranslateService,
    private _sanitizer: DomSanitizer) { }

  transform(product: Product, pcs: string = ''): SafeHtml {
    let unit = 'L'
    if (product.unit == 'g') {
      unit = 'kg'
    } else if (product.unit == 'pcs') {
      unit = 'stk'
    }
    if (product.unit !== 'pcs') {
      const price = (product.price * 1000) / product.weight;
      return this._sanitizer.bypassSecurityTrustHtml(
        `<span>${price.toFixed(2).replace('.', ',')}/${unit}</span>`
      );
    } else {
      const price = (product.price) / product.weight;
      return this._sanitizer.bypassSecurityTrustHtml(
        `<span>${price.toFixed(2).replace('.', ',')}/${unit}</span>`
      );
    }

  }

}
