import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { AddressService } from 'src/app/services/address/address.service';

@Component({
  selector: 'app-check-postalcode',
  templateUrl: './check-postalcode.component.html',
  styleUrls: ['./check-postalcode.component.scss'],
})
export class CheckPostalcodeComponent implements OnInit {

  constructor(private modal: ModalController, private _loading: LoadingController, private _alert: AlertController, private _firestore: AngularFirestore,
    private _address: AddressService) { }
  plz = '';
  ngOnInit() { }
  dismiss() {
    this.modal.dismiss();
  }
  async checkPLZ() {
    const loading = await this._loading.create({
    });
    loading.present();

    const deliveryArea = await this._firestore.collection('deliveryArea').doc(this.plz).get().toPromise();
    if (deliveryArea.exists) {
      loading.dismiss();
      this._address.plz = this.plz;
      const alert = await this._alert.create({
        header: "Du kannst bestellen 🎉",
        message: 'Wir liefern in diesem Gebiet!',
        buttons: [
          {
            text: 'Okay',
            handler: () => {
              this.modal.dismiss();
            }
          }
        ]
      });
      alert.present();
    } else {
      loading.dismiss();
      const alert = await this._alert.create({
        message: 'Wir liefern derzeit noch nicht in dieses Gebiet!',
        buttons: [
          {
            text: 'Okay',

          }
        ]
      });

      alert.present();
      throw 'Not deliverying';
    }
  }

}
