import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from 'src/app/types/product';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {
  maximum = 0;

  constructor(private http: HttpClient) { }

  getMaximum() {
    this.http.get<any>(`${environment.api_url}/products/discount/maximum`).subscribe(result => {
      this.maximum = result.amount;
    })

  }
  checkMaximum(amount: number, product: Product) {
    if (!product.discount) {
      return true;
    }
    if ((amount + 1) > this.maximum) {
      return false;
    } else {
      return true;
    }
  }
}
