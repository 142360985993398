import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

import { extension } from "../../../assets/data/extensions";

interface Country {
  code: string;
  name: string;
  extension: string;
}

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
})
export class CountrySelectComponent implements OnInit {

  results: Subject<Country[]> = new Subject();
  resultsRaw: Country[] = [];
  searchString: string;

  constructor(private _modal: ModalController) { }

  ngOnInit() {
    setTimeout(() => this.loadCountries());
  }

  loadCountries() {
    this.resultsRaw = extension;
    this.results.next(this.resultsRaw);
  }

  selectCountry(country: Country) {
    this._modal.dismiss(country);
  }

  updateSearch() {
    if (!this.searchString) {
      this.results.next(this.resultsRaw);
      return;
    }
    this.results.next(this.resultsRaw.filter(c => c.name.includes(this.searchString)));
  }

  dismiss() {
    this._modal.dismiss();
  }

}
