import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  activityCount = '';

  constructor(private _afAuth: AngularFireAuth, private _http: HttpClient) {

  }
  markAsRead() {
    this.activityCount = '';
    this._afAuth.authState.subscribe(user => {
      user.getIdToken().then(idToken => {
        this._http.post(`${environment.api_url}/activity/read`, {}, {
          headers: {
            authorization: 'Bearer ' + idToken
          }
        }).subscribe(result => {
          console.log(result);

        })
      });
    });
  }
  getActivityCount() {
    this._afAuth.authState.subscribe(user => {
      user.getIdToken().then(idToken => {
        this._http.get<any>(`${environment.api_url}/activities/unread`, {
          headers: {
            authorization: 'Bearer ' + idToken
          }
        }).subscribe(count => {
          if (count.count > 99) {
            this.activityCount = '99+';
          } else {
            this.activityCount = count.count;
          }

        })
      })
    })

  }

}
