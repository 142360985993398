import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ModalController } from '@ionic/angular';
import * as dayjs from 'dayjs';
import { Order, OrderStatement } from 'src/app/types/product';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile-orders',
  templateUrl: './profile-orders.component.html',
  styleUrls: ['./profile-orders.component.scss'],
})
export class ProfileOrdersComponent implements OnInit {

  orders: OrderStatement[] = [];
  dayjs = dayjs;
  showLoader = true;

  constructor(private _firestore: AngularFirestore,
    private _auth: AngularFireAuth,
    private activatedRoute: ActivatedRoute,
    private _modal: ModalController) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      if (params.orderId) {
        this._modal.dismiss();
        const modal = await this._modal.create({
          component: OrderDetailComponent,
          componentProps: {
            orderId: params.orderId
          }
        });
        await modal.present();
      }
    });

    this.loadOrders();
  }

  async openDetail(orderId: string) {
    const modal = await this._modal.create({
      component: OrderDetailComponent,
      cssClass: 'big-modal',
      componentProps: {
        orderId
      }
    });
    await modal.present();
  }

  async loadOrders(event = null) {
    this._auth.authState.subscribe(async (user) => {
      if (user) {
        const orders = await this._firestore.collection<Order>('orders', ref =>
          ref.where('userId', '==', user.uid).orderBy('order_date', 'desc')).get().toPromise();
        this.orders = orders.docs.map(d => ({
          order: d.data(),
          orderId: d.id
        })).sort((a, b) => b.order.order_date.toDate().getTime() - a.order.order_date.toDate().getTime());

        this.showLoader = false;
        if (event) {
          event.target.complete();
        }
      }
    });
  }
}
