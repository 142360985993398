import { Product } from "../types/product";

class StockUtil {
  check(product: Product, qty: number = -1) {
    if (product.lockstock == false) {
      return true;
    }

    if (product.stock > qty || (qty == -1 && product.stock > 0)) {
      return true;
    }
    return false;
  }
}

export default new StockUtil;