import { Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Geolocation } from '@capacitor/geolocation';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import { LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { TranslateService } from '@ngx-translate/core';
import { App, URLOpenListenerEvent, } from '@capacitor/app';

import * as dayjs from 'dayjs';
import { CartService } from './services/cart/cart.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProductDetailPage } from './components/product-detail/product-detail.page';
import { HttpClient } from '@angular/common/http';
import { Product } from './types/product';
import { environment } from 'src/environments/environment';
import { url } from 'inspector';
import { AffiliateService } from './services/affiliate/affiliate.service';
import { filter } from 'rxjs/operators';
import { NgxGdprCookieConsentService } from 'ngx-gdpr-cookie-consent';
import { ScriptService } from './services/script/script.service';
import { DiscountService } from './services/discount/discount.service';
declare const gtag: Function; // <------------Important: the declartion for gtag is required!
declare let gapi: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isWeb = false;
  constructor(private _afAuth: AngularFireAuth,
    private _loading: LoadingController,
    private _platform: Platform,
    private _lang: TranslateService,
    private _cart: CartService,
    private affiliate: AffiliateService,
    private _modalController: ModalController,

    private router: Router,
    private zone: NgZone,
    private _ref: ElementRef,
    private _http: HttpClient,
    private _script: ScriptService,
    private renderer: Renderer2,
    private _cookieService: NgxGdprCookieConsentService,
    private discount: DiscountService,
  ) {
    discount.getMaximum();
    if (this._platform.is('capacitor') || this._cookieService.hasConsent('external')) {
      if (this._platform.is('capacitor')) {
        const scriptElement = this._script.loadJsScript(this.renderer, 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDju49z4v2RYLAUwk_L1iauwsELbvJqnC0&libraries=places');
        scriptElement.onload = () => {
          gapi.load('client', this.initialize());
        }
        scriptElement.onerror = () => {
        }
        this.isWeb = true;
      } else {
        this.isWeb = false;
      }
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        try {
          gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects
          })
        } catch (e) {
        }
      });
    }
    this.initialize();

  }
  ngOnInit(): void {

    this.affiliate.checkAffiliateValue();
    this.router.events.subscribe(async val => {
      if (val instanceof NavigationEnd) {
        if (val.url.includes('productdetails')) {

          const id = val.url.split('/')[val.url.split('/').length - 1];
          this.showProductDetailModal(id);
        } else if (val.url.includes('affiliate')) {
          const id = val.url.split('/')[val.url.split('/').length - 1];
          this.validateAffiliateCode(id);

        }
      }
    });


    this.determineIsLoggedIn();
    this.loadLanguage();
    if (this._platform.is('capacitor')) {
      this.setStatusBar();
      Geolocation.requestPermissions();
    }
  }
  async validateAffiliateCode(code: string) {
    this._cart.saveAffiliate(code);
  }

  initialize() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {

      this.zone.run(() => {

        const slug = event.url.split(".de").pop();
        if (slug) {
          if (slug.includes('productdetails')) {
            const productId = slug.split('/').pop();
            this.showProductDetailModal(productId);
          } else if (slug.includes('affiliate')) {
            const id = slug.split('/')[slug.split('/').length - 1];
            this.validateAffiliateCode(id);
          } else {
            this.router.navigateByUrl(slug);
          }
        }
      });
    });
  }


  async showProductDetailModal(productId: string) {
    const loading = await this._loading.create({
    });
    loading.present();
    this._http.get<Product>(`${environment.api_url}/products/${productId}`).subscribe(async result => {
      this._modalController.dismiss();
      loading.dismiss();
      if (!this._platform.is('android')) {
        const modal = await this._modalController.create({
          component: ProductDetailPage,
          canDismiss: true,
          mode: 'ios',
          presentingElement: this._ref.nativeElement,
          componentProps: {
            product: result
          }
        });
        await modal.present();
      } else {
        const modal = await this._modalController.create({
          component: ProductDetailPage,
          canDismiss: true,
          mode: 'md',
          componentProps: {
            product: result
          }
        });
        await modal.present();
      }
    });
  }
  async setStatusBar() {
    if (!this._platform.is('android')) {
      await StatusBar.setStyle({ style: Style.Dark });
    }
  }

  async determineIsLoggedIn() {
    this._afAuth.authState.subscribe(state => {
      if (!state) {

      } else {
        this._cart.loadDeliveryDate();
      }
      SplashScreen.hide();
    });
  }

  async loadLanguage() {
    const lang = (await Preferences.get({ key: 'language' })).value ?? 'de';
    require(`../assets/i18n/dayjs/${lang}.js`);
    dayjs.locale(lang);

    this._lang.setDefaultLang('de');
    this._lang.use(lang);
  }
}
