import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class ActivitiesComponent implements OnInit {
  activities = [];
  constructor(private _http: HttpClient, private afAuth: AngularFireAuth, public _translate: TranslateService, private router: Router, private activityService: ActivityService, private _loading: LoadingController) { }
  loading = true
  ngOnInit() {
    this.activityService.markAsRead();
    this.getActivities();
  }

  getMonth(object: any) {
    const item = object.all[0];
    const date = new Date(item.date);
    const month = date.getMonth() + 1;
    switch (month) {
      case 1:
        return "Januar";
      case 2:
        return "Februar"
      case 3:
        return "März"
      case 4:
        return "April"
      case 5:
        return "Mai"
      case 6:
        return "Juni"
      case 7:
        return "Juli"
      case 8:
        return "August"
      case 9:
        return "September"
      case 10:
        return "Oktober"
      case 11:
        return "November"
      case 12:
        return "Dezember"
    }
    return "November";
  }
  getYear(object: any) {
    const item = object.all[0];
    const date = new Date(item.date);
    return date.getFullYear();
  }

  async getActivities() {
    const loading = await this._loading.create({
    });
    loading.present();
    this.afAuth.authState.subscribe(user => {
      user.getIdToken().then(token => {
        this._http.get<any>(`${environment.api_url}/activities`,
          {
            headers: {
              authorization: 'Bearer ' + token
            }
          }).subscribe(result => {
            this.loading = false;
            this.activities = result;
            loading.dismiss();
          })

      })
    })

  }
  openLink(object: any) {
    if (object.link) {
      if (!object.link.includes('app.vomtuerken.de')) {
        window.open(object.link, "_blank");
        return;
      }
      const route = object.link.replace("https://app.vomtuerken.de", "");
      this.router.navigate([route]);
    }
  }
}
