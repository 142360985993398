import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { CountrySelectComponent } from 'src/app/components/country-select/country-select.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Preferences } from '@capacitor/preferences';
import { AddressService } from 'src/app/services/address/address.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
})
export class ProfileSettingsComponent implements OnInit {

  userData = {
    firstname: '',
    lastname: '',
    mobile: '',
    mobileCountry: {
      code: '',
      extension: ''
    },
    email: '',
    notifications: true
  }
  loader: HTMLIonLoadingElement;

  constructor(private _authService: AuthService,
    private _auth: AngularFireAuth,
    private _firestore: AngularFirestore,
    private _nav: NavController,
    private _address: AddressService,
    private _translate: TranslateService,
    private _alert: AlertController,
    private _loading: LoadingController,
    private http: HttpClient,
    private _modal: ModalController) { }



  ngOnInit() {

    this.loadUserData();
  }

  async openCountrySelect() {
    const modal = await this._modal.create({
      component: CountrySelectComponent
    });
    await modal.present();
    const data = await modal.onDidDismiss();

    if (data?.data) {
      this.userData.mobileCountry = {
        code: data.data.code.toLowerCase(),
        extension: data.data.extension
      }
    }
  }


  async logout() {

    this._address.clearCurrent();
    this._authService.logout();
  }


  loadUserData() {
    this._auth.authState.subscribe(userId => {
      if (userId) {
        this._firestore.collection('users').doc<any>(userId.uid).valueChanges().subscribe(user => {
          this.userData = {
            firstname: user.firstname ?? '',
            lastname: user.lastname ?? '',
            email: userId.email,
            mobile: user.mobile ?? '',
            mobileCountry: user.mobileCountry ?? {
              code: 'de',
              extension: '+49'
            },
            notifications: user.notifications ?? true
          };
        })
      }
    });
  }

  async saveUserData() {
    const user = await this._auth.currentUser;
    if (user) {
      await this._firestore.collection('users').doc(user.uid).update(this.userData);
      this._nav.navigateRoot('/tabs/profile');
      this.loader.dismiss();
    }
  }

  async askForDeleteAccount() {
    const headline = String(await this._translate.get('delete_headline').toPromise());
    const body = String(await this._translate.get('delete_body').toPromise());
    const signInBtn = String(await this._translate.get('yes').toPromise());
    const cancelBtn = String(await this._translate.get('cancel').toPromise());

    const alert = await this._alert.create({
      header: headline,
      message: body,
      buttons: [
        {
          text: cancelBtn,
        },
        {
          text: signInBtn,
          handler: async () => {
            const loading = await this._loading.create({
            });
            loading.present();
            const user = await this._auth.currentUser;
            const idToken = await user.getIdToken();
            this.http.delete<any>(`${environment.api_url}/user`, {
              headers: {
                authorization: 'Bearer ' + idToken
              }
            }).subscribe(result => {
              this._auth.signOut();
              loading.dismiss();
              this._nav.navigateRoot('/login');
            })
          }
        }
      ]
    });
    await alert.present();
  }
}
