import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LoadingController } from '@ionic/angular';
import { CartService } from '../cart/cart.service';
import { Preferences } from '@capacitor/preferences';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  affiliateAllowed = true;
  constructor(private _afAuth: AngularFireAuth, private _loading: LoadingController, private _cart: CartService, private _http: HttpClient) { }

  async checkAffiliate() {
    const sub = this._afAuth.authState.subscribe(async user => {
      if (user) {
        sub.unsubscribe();
        if (this._cart.affiliateCode) {
          const loading = await this._loading.create({
          });
          loading.present();
          this._cart.validateCode(this._cart.affiliateCode, loading);
          this._cart.affiliateCode = '';
        } else {
          const affiliate = await Preferences.get({ key: 'affiliate' });
          if (affiliate.value) {
            const loading = await this._loading.create({
            });
            loading.present();
            this._cart.validateCode(affiliate.value, loading);
            Preferences.remove({ key: 'affiliate' })
            this._cart.affiliateCode = '';
            this._cart.validateCode(affiliate.value, loading);
          }
        }
      }
    })
  }

  checkAffiliateValue() {
    this._afAuth.authState.subscribe(async user => {
      if (!user) {
        return;
      }
      const idToken = await user.getIdToken();

      this._http.get<any>(`${environment.api_url}/affiliate/state`, {
        headers: {
          authorization: 'Bearer ' + idToken
        }
      }).subscribe(result => {
        this.affiliateAllowed = result.message;
      })

    })

  }
}
