// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyDju49z4v2RYLAUwk_L1iauwsELbvJqnC0",
    authDomain: "vomtuerken-app.firebaseapp.com",
    projectId: "vomtuerken-app",
    storageBucket: "vomtuerken-app.appspot.com",
    messagingSenderId: "790448970812",
    appId: "1:790448970812:web:7831fff5184e92393b7739",
    measurementId: "G-FL1RFBRVHL"
  },
  api_url: 'https://europe-west1-vomtuerken-app.cloudfunctions.net/v1',
  //api_url: 'http://localhost:3000',
  production: false,
  version: 'beta',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
