import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Product } from 'src/app/types/product';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(product: Product): SafeHtml {
    if (product.discount && product.discount > 0) {
      return this._sanitizer.bypassSecurityTrustHtml(
        `<span class="price">
        <span class="discount-strikethrough">${product.price.toFixed(2).replace('.', ',')} &euro;</span> 
        <span class="discount-price">${(product.price - product.discount).toFixed(2).replace('.', ',')} &euro;</span>
        </span>`
      );
    } else {
      return this._sanitizer.bypassSecurityTrustHtml(
        `<span class="price">${product.price.toFixed(2).replace('.', ',')} &euro;</span>`
      );
    }
  }

}
