import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Optional } from '@angular/core';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { AlertController, IonRouterOutlet, LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ProductDetailPage } from 'src/app/components/product-detail/product-detail.page';
import { Product } from 'src/app/types/product';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-products',
  templateUrl: './search-products.page.html',
  styleUrls: ['./search-products.page.scss'],
})
export class SearchProductsPage implements OnInit {
  loadingEvent: any;
  isMobile = true;
  isScanning = false;
  constructor(
    private _http: HttpClient,
    private _ref: ElementRef,
    private _nav: NavController,
    private _platform: Platform,
    private _translate: TranslateService,
    private _loading: LoadingController,
    private _alert: AlertController,
    @Optional() private _routerOutlet: IonRouterOutlet,
    private _modal: ModalController) {
    this._platform.backButton.subscribeWithPriority(10, () => {
      this.isScanning = false;
      BarcodeScanner.showBackground();
      BarcodeScanner.stopScan();
      document.querySelector('body').classList.remove('scanner-active');
    });
  }
  page = 0;
  size = 10;
  searchString = '';
  dataSet = [];
  ngOnInit() {
    if (this._platform.is('capacitor')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  goBack() {
    const defaultHref = '/tabs/products';
    if (this._routerOutlet && this._routerOutlet.canGoBack()) {
      this._nav.setDirection('back', undefined, undefined);
      this._routerOutlet.pop();
    } else if (defaultHref != null) {
      this._nav.navigateBack(defaultHref);
    }
  }

  cancelScan() {
    this.isScanning = false;
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    document.querySelector('body').classList.remove('scanner-active');
  }

  async startScanner() {
    if (this.loadingEvent) {
      this.loadingEvent.target.complete();
    }
    this.isScanning = true;
    document.querySelector('body').classList.add('scanner-active');
    await BarcodeScanner.prepare();
    await BarcodeScanner.checkPermission({ force: true });
    BarcodeScanner.hideBackground();
    const result = await BarcodeScanner.startScan();

    if (result.hasContent) {
      this.isScanning = false;
      this.searchProduct(result.content)
    }

  }
  async searchProduct(content: string) {
    const loading = await this._loading.create({
    });
    loading.present();
    this._http.get<Product>(`${environment.api_url}/products/ean/${content}`).subscribe(result => {
      loading.dismiss();
      if (!result) {
        this.showNotFound();
      } else {
        this.openModal(result);
      }
    })
  }
  async showNotFound() {
    const headline = String(await this._translate.get('product_not_found').toPromise());
    const body = String(await this._translate.get('product_not_found_body').toPromise());
    const cancelBtn = String(await this._translate.get('ok').toPromise());
    const alert = await this._alert.create({
      header: headline,
      message: body,
      buttons: [
        {
          text: cancelBtn,
        },
      ]
    });

    await alert.present();
  }
  getResults($event) {
    if ($event != null) {
      this.searchString = $event;
    }

    if (this.searchString !== '') {
      if (this.page == 0) {
        this.dataSet = [];
      }
      this._http.get<any>(`${environment.api_url}/products/search?query=${this.searchString}&page=${this.page}&size=${this.size}`).subscribe(result => {
        this.dataSet = this.dataSet.concat(result);
        if (this.loadingEvent) {
          this.loadingEvent.target.complete();
        }
      })
    } else {
      this.dataSet = [];
      this.page = 0;
    }
  }

  async openModal(product: Product) {
    if (!this._platform.is('android')) {
      const modal = await this._modal.create({
        component: ProductDetailPage,
        canDismiss: true,
        mode: 'ios',
        presentingElement: this._ref.nativeElement,
        componentProps: {
          product: product
        }
      });
      await modal.present();
    } else {
      const modal = await this._modal.create({
        component: ProductDetailPage,
        canDismiss: true,
        mode: 'md',
        componentProps: {
          product: product
        }
      });
      await modal.present();
    }
  }

  loadData($event) {
    this.loadingEvent = $event;
    this.page = this.page + 1;
    this.getResults(null);
  }
}
